import React, { useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap"; // Import Modal and Button from React Bootstrap
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import IconTimesSquare from "../icons/IconTimesSquare";
import IconCopy from "../icons/IconCopy";
import IconcheckSquare from "../icons/IconcheckSquare";
import Connect from "../components/Connect";
import IconTriangleExclamation from "../icons/IconTriangleExclamation";

const Home = () => {
  const { t } = useTranslation();
  const basePath = "banner.";

  const [activeTasks, setActiveTasks] = useState([]);
  const [isInputActive, setIsInputActive] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);

  const handleTaskClick = (index) => {
    setActiveTasks((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const tasks = [
    { id: 1, label: "Subscribe #1" },
    { id: 2, label: "Subscribe #2" },
    { id: 3, label: "Subscribe #3" },
  ];

  const handleSendClick = () => setIsInputActive(true);

  const handleWalletModalShow = () => setShowWalletModal(true);
  const handleWalletModalClose = () => setShowWalletModal(false);

  return (
    <>
      <NavBar />

      <section className="main">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="content">
                <h1>
                  {t(`${basePath}title1`)} <br />
                  {t(`${basePath}title2`)}
                </h1>

                <div className="btn-mob">
                  <button className="btn-st" onClick={handleWalletModalShow}>
                    {t(`${basePath}walletTitle`)}
                  </button>
                </div>

                <div className="presale-card-wrapper">
                  <div className="presale-card">
                    <div className="presale-card-body">
                      <h4>⚡{t(`${basePath}insert`)}</h4>
                      <div
                        className={`card-body__input ${
                          isInputActive ? "active" : ""
                        }`}
                      >
                        <input
                          type="text"
                          className="card-body__task"
                          placeholder="https://twitter.com/..."
                        />
                        {isInputActive ? (
                          <IconcheckSquare
                            width="22"
                            height="22"
                            fill="#36ff92"
                          />
                        ) : (
                          <IconTimesSquare
                            width="22"
                            height="22"
                            fill="#1d9bf0"
                          />
                        )}
                      </div>
                      <h4>⚡{t(`${basePath}subscribe`)}</h4>
                      {tasks.map((task, index) => (
                        <a
                          key={task.id}
                          href="https://tokengift.org/"
                          target="__blank"
                          className={`card-body__task ${
                            activeTasks[index] ? "active" : ""
                          }`}
                          onClick={(e) => {
                            handleTaskClick(index);
                          }}
                        >
                          <span>
                            <img
                              src={
                                require("../assets/img/social/twitter.svg")
                                  .default
                              }
                              alt=""
                            />
                            {task.label}
                          </span>
                          {activeTasks[index] ? (
                            <IconcheckSquare
                              width="22"
                              height="22"
                              fill="#36ff92"
                            />
                          ) : (
                            <IconTimesSquare
                              width="22"
                              height="22"
                              fill="#1d9bf0"
                            />
                          )}
                        </a>
                      ))}
                      {isInputActive ? (
                        <div className="success">
                          {t(`${basePath}successfully`)}
                        </div>
                      ) : (
                        <div className="card-body__btn">
                          <button className="btn-st" onClick={handleSendClick}>
                            {t(`${basePath}btnSend`)}
                          </button>
                        </div>
                      )}

                      <h4 className="mt-30">
                        ⚡{t(`${basePath}walletTitle`)}:
                        <span className="icon">
                          <img
                            src={require("../assets/img/trust.svg").default}
                            alt=""
                          />
                          <img
                            src={require("../assets/img/exodus.svg").default}
                            alt=""
                          />
                          <img
                            src={require("../assets/img/binance.svg").default}
                            alt=""
                          />
                          <img
                            src={require("../assets/img/coinbase.svg").default}
                            alt=""
                          />
                        </span>
                      </h4>
                      <button
                        className="btn-st"
                        onClick={handleWalletModalShow}
                      >
                        {t(`${basePath}walletTitle`)}
                      </button>

                      <h4 className="mt-30">⚡{t(`${basePath}share`)}</h4>
                      <div className="card-body__input">
                        <input
                          type="text"
                          className="card-body__task"
                          placeholder="https://link.com/..."
                        />
                        <IconCopy width="22" height="22" fill="#1d9bf0" />
                      </div>
                    </div>
                  </div>
                  <div className="info">
                    <IconTriangleExclamation
                      width="40"
                      height="40"
                      fill="#1dff96"
                    />
                    {t(`${basePath}info`)}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="scroll-slider-wrapper"></div>
      </section>

      <Footer />
      <ToastContainer />

      {/* Modal for Connect Wallet */}
      <Modal show={showWalletModal} onHide={handleWalletModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>⚡{t(`${basePath}walletTitle`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Connect />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleWalletModalClose}>
            {t(`${basePath}btnClose`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
