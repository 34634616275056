import React from "react";

const IconTriangleExclamation = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M18.692,22.75H5.309A4.047,4.047,0,0,1,1.732,16.8L8.339,4.442a4.153,4.153,0,0,1,7.322,0L22.268,16.8a4.046,4.046,0,0,1-3.576,5.952ZM12,3.75a2.614,2.614,0,0,0-2.339,1.4L3.055,17.505A2.546,2.546,0,0,0,5.309,21.25H18.692a2.546,2.546,0,0,0,2.253-3.745h0L14.339,5.149A2.614,2.614,0,0,0,12,3.75ZM12.75,14V10a.75.75,0,0,0-1.5,0v4a.75.75,0,0,0,1.5,0Zm.27,3a1,1,0,0,0-1-1h-.01a1,1,0,1,0,1.01,1Z"
      />
    </svg>
  );
};

export default IconTriangleExclamation;
