import React from "react";

const IconcheckSquare = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M16.03,9.136a.75.75,0,0,1,0,1.061l-4.666,4.667a.752.752,0,0,1-1.061,0L7.97,12.53a.75.75,0,0,1,1.06-1.06l1.8,1.8L14.97,9.136A.751.751,0,0,1,16.03,9.136Zm5.72-2.761v11.25c0,2.66-1.465,4.125-4.125,4.125H6.375c-2.66,0-4.125-1.465-4.125-4.125V6.375c0-2.66,1.465-4.125,4.125-4.125h11.25C20.285,2.25,21.75,3.715,21.75,6.375Zm-1.5,0c0-1.84-.785-2.625-2.625-2.625H6.375c-1.84,0-2.625.785-2.625,2.625v11.25c0,1.84.785,2.625,2.625,2.625h11.25c1.84,0,2.625-.785,2.625-2.625Z"
      />
    </svg>
  );
};

export default IconcheckSquare;
