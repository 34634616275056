import React from "react";

const IconCopy = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        d="M18.375,21.75H9.625A3.044,3.044,0,0,1,6.25,18.375V9.625A3.044,3.044,0,0,1,9.625,6.25h8.75A3.044,3.044,0,0,1,21.75,9.625v8.75A3.044,3.044,0,0,1,18.375,21.75Zm-8.75-14c-1.332,0-1.875.543-1.875,1.875v8.75c0,1.332.543,1.875,1.875,1.875h8.75c1.332,0,1.875-.543,1.875-1.875V9.625c0-1.332-.543-1.875-1.875-1.875ZM3.75,15V5.625c0-1.332.543-1.875,1.875-1.875H15a.75.75,0,0,0,0-1.5H5.625A3.044,3.044,0,0,0,2.25,5.625V15a.75.75,0,0,0,1.5,0Z"
      />
    </svg>
  );
};

export default IconCopy;
