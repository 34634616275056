export const TRANSLATIONS_EN = {
    navBar: {
        lang: {
            "en-US": "English",
            "es-ES": "Spanish",
        }
    },

    banner: {
        title1: "Get 20 USDT for completing a",
        title2: "simple task",
        subscribe: "Subscribe to the sponsor's social page to get rewards:",
        insert: "Insert your Twitter account link to prove ahead the following action performed",
        successfully: "Assignment successfully completed!",
        btnSend: "Send",
        share: "Share the link below with your friends to receive $2 for every person referred",
        info: "Once you have signed up for social media you need to wait 21 days without unsubscribing to get the reward and extra referring reward.",
        connection: "Connection...",
        btnMeta: "Connect Metamask",
        btnTron: "Connect Tron",
        btnTronLink: "Connect TronLink",
        wallet: "Wallet connected:",
        walletTitle: "Connect Wallet",
        disconnect: "Disable Wallet",
        btnClose: "Close",
        error: {
            tronInfo: "Please connect your wallet",
            tronLink: "TronLink connection error:",
            tronLinkInstall: "TronLink is not installed",
            token: "Wallet not found",
            trc20: "Failed to approve TRC20 token",
        },
    },

    footer: {
        desc: "Connect your wallet and get tokens first! After connecting your wallet, expect news in official sources. Success!",
        copy: 'Copyright © 2024 Gittu. All rights reserved.',
    }
};
