export const TRANSLATIONS_ES = {
    navBar: {
        lang: {
            "en-US": "Inglés",
            "es-ES": "Español",
        }
    },

    banner: {
        title1: "Consigue 20 USDT por completar",
        title2: "una tarea sencilla",
        subscribe: "Suscríbete a la página social del patrocinador para obtener recompensas:",
        insert: "Inserte el enlace de su cuenta de Twitter para demostrar que ha realizado la siguiente acción",
        successfully: "¡Asignación completada con éxito!",
        btnSend: "Enviar",
        share: "Comparta el siguiente enlace con sus amigos y reciba 2 $ por cada persona que recomiende.",
        info: "Una vez que te hayas dado de alta en las redes sociales, tienes que esperar 21 días sin darte de baja para obtener la recompensa y la recompensa extra por recomendación.",
        connection: "Conexión...",
        btnMeta: "Conectar Metamask",
        btnTron: "Conectar Tron",
        btnTronLink: "Conectar TronLink",
        wallet: "Cartera conectada:",
        walletTitle: "Billetera Connect",
        disconnect: "Desactivar Cartera",
        btnClose: "Cerrar",
        error: {
            tronInfo: "Conecte su monedero",
            tronLink: "Error de conexión TronLink:",
            tronLinkInstall: "TronLink no está instalado",
            token: "Cartera no encontrada",
            trc20: "No se aprueba la ficha TRC20",
        },
    },

    footer: {
        desc: "¡Conecta tu monedero y consigue tokens primero! Después de conectar tu monedero, espera noticias en fuentes oficiales. ¡Éxito!",
        copy: 'Copyright © 2024 Gittu. Todos los derechos reservados.',
    }
};
